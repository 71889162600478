import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/common/Layout';
import Title from '../components/common/Title';
import PostList from '../components/postList/PostList';
import Pagination from '../components/common/Pagination';

type Props = {
  data: {
    allContentfulBlogPost: {
      edges: any[]
    },
    contentfulTag: {
      name: string,
      slug: string
    }
  },
  pageContext: {
    prev: string|null,
    next: string|null
  }
}

export default ({ data, pageContext }: Props) => {
  const posts = data.allContentfulBlogPost.edges;

  return (
    <Layout>
      <Title subTitle={data.contentfulTag.name} />
      <PostList posts={posts} head={data.contentfulTag.name} />
      <Pagination prev={pageContext.prev} next={pageContext.next}/>
    </Layout>
  )
}

export const taggedPostListQuery = graphql`
  query taggedPostListQuery($skip: Int!, $limit: Int!, $tag: String!) {
    allContentfulBlogPost(
      filter: { tags: { elemMatch: { slug: { in: [$tag] } } } }
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          title
          createdAt
          tags {
            slug
            name
          }
        }
      }
    }
    contentfulTag(slug: {eq: $tag}) {
      name
      slug
    }
  }
`